$font: "robotocondensed regular", sans-serif;
$fontL: "robotocondensed light", sans-serif;
$fontB: "robotocondensed bold", sans-serif;

$hFont: "robotocondensed regular", sans-serif;

$pFont: "LetterGothicMTStd", sans-serif;
$pFontB: "LetterGothicMTStd Bold", sans-serif;
$qFont: "BodoniSevITC Book", sans-serif;

// /* Darks */
$black: #000000;
$grey: #373838;
// $grey-42: rgba(55, 56, 56, 0.42);
$grey-42: rgba(55, 56, 56, 0.21);
$grey-alt: #4b4b48;
$not-black: #191817;
// $not-black-42: rgba(25, 24, 23, 0.42);
$not-black-42: rgba(25, 24, 23, 0.21);

// /* Lights */
$paper: #fffcf2;
// $paper-dark: #d4ceba;
$paper-dark: #c1c1c1;
$paper-darker: #aca795;
$white: #ffffff;
$paper-dark-21: rgba(212, 206, 186, 0.21);
$paper-21: rgba(255, 252, 242, 0.21);
$paper-42: rgba(255, 252, 242, 0.42);

$theme: (
    font: $font,
    fontL: $fontL,
    fontB: $fontB,
    hFont: $hFont,
    pFont: $pFont,
    qFont: $qFont,
    baseFontSize: 14px,
    color: #c1c1c1,
    scColor: #373838,
    write: rgb(5, 5, 5),
    bg: #e9e9e9,
    text: #373838,
    link: #373838,
    brdr: #969696,
    header-bg: transparent,
    header-text: #b8b8b8,
    header-icon: #969696,
    craft: (
        el: #4b4b48,
        text: #c1c1c1,
        color: #232323,
        colorL: #494949,
        heading: #494949,
    ),
    essence: (
        el: #fffcf2,
        text: #373838,
        color: #c0c0c0,
        colorL: #f8f8f8,
        header: #c0c0c0,
    ),
    types: (
        primary: #373838,
        success: #19b820,
        info: #006ed0,
        warning: #ef6c00,
        danger: #d9544f,
        craft: #191817,
        essence: #c1c1c1,
        white: #fff,
        black: #000,
    ),
);

$craft: map-get($theme, craft);
$essence: map-get($theme, essence);
