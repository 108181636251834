@import "variables";

@font-face {
  font-family: 'icon';
  src:  url('#{$icomoon-font-path}/icon.eot?7ptpdw');
  src:  url('#{$icomoon-font-path}/icon.eot?7ptpdw#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icon.ttf?7ptpdw') format('truetype'),
    url('#{$icomoon-font-path}/icon.woff?7ptpdw') format('woff'),
    url('#{$icomoon-font-path}/icon.svg?7ptpdw#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-back {
  &:before {
    content: $i-back; 
  }
}
.i-close {
  &:before {
    content: $i-close; 
  }
}
.i-linkedin {
  &:before {
    content: $i-linkedin; 
  }
}

