.menuHeader,
header.header {
  top: 0;
  left: 0;
  width: 100%;
  height: 105px;
  z-index: 1000;
  position: fixed;
  pointer-events: none;
  @include clearfix;

  a,
  button {
    pointer-events: all;
  }

  .brand {
    top: 0vh;
    left: 0px;
    opacity: 1;
    width: 260px;
    height: 100%;
    position: absolute;
    will-change: opacity;
    padding: 37px 0px 37px 20px;
    font-family: map-get($theme, fontL);

    @include prefix(
      (
        transition: opacity ease-out 1.2s,
        transition-delay: 0.8s,
      ),
      moz ms webkit
    );

    // &,
    // svg,
    // .fill-theme {
    //     @include prefix((
    //         transition: all ease-in 1s
    //     ), moz ms webkit );
    // }
    .tagline {
      left: 98%;
      opacity: 1;
      width: 100%;
      bottom: 35px;
      width: 320px;
      display: block;
      position: absolute;
      font-size: em(21px);
      visibility: visible;
      pointer-events: none;
      font-family: map-get($theme, fontL);

      @include prefix(
        (
          transition: all ease-in 0.8s,
          transition-delay: 2.1s,
        ),
        moz ms webkit
      );

      // ._essence { }
      // ._craft { }
    }

    svg {
      right: 0;
      left: 20px;
      width: 80%;
      bottom: 40%;
      display: block;
      margin: 0 auto;
      // max-width: 80%;
      max-width: 210px;
      position: absolute;
    }

    & > img {
      // opacity: 0;
      margin-right: 17px;
      display: inline-block;
      vertical-align: bottom;
    }

    .fill-theme {
      fill: map-get($theme, header-text);
    }
  }

  a {
    padding: 0;
    width: 105px;
    height: 105px;
    display: block;
    font-size: 32px;
    overflow: hidden;
    text-align: center;
    position: relative;
    color: map-get($theme, header-text);

    &.menu {
      & > div {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 36px;
        height: 36px;
        margin: auto;
        position: absolute;
        border-radius: 50%;
        &.contact {
          top: -2px;

          @include contact;
        }
        svg {
          display: none;
        }
        &:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 35px;
          height: 2px;
          content: "";
          position: absolute;
          top: calc(50% - 1px);
          background: map-get($theme, header-icon);
          box-shadow: 0 12px map-get($theme, header-icon), 0 -12px map-get($theme, header-icon);
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: transparent;

      & > div {
        &.contact {
          @include prefix(
            (
              transform-origin: center bottom,
            ),
            moz ms webkit
          );
        }
      }
    }
  }

  .menubar {
    @include notaList;

    &.R {
      float: right;
    }

    & > li {
      float: left;

      a {
        @include prefix(
          (
            transition: all 0.84s ease-in-out,
          ),
          moz ms webkit
        );

        &:hover,
        &:focus,
        &:active {
          @include prefix(
            (
              transform: scale(1.21),
              transition-duration: 0.4s,
              transform-origin: center center,
            ),
            moz ms webkit
          );
        }
      }
    }
  }
}
