// body.HOME
.HOME {
  header.header {
    .brand {
      // opacity: 0;
      pointer-events: none !important;

      // left: 0;
      // top: 50vh;
      // height: 0;
      // padding: 0;
      // width: 100%;
      // & > img {
      //     display: none;
      // }
      // svg {
      //     max-width: 815px;
      //     position: absolute;
      //     height: auto !important;
      // }
      // .fill-theme {
      //     fill: white;
      // }
      .tagline {
        opacity: 0;
        visibility: hidden;

        @include prefix(
          (
            transition-duration: 1.2s,
            transition-delay: '0s !important'
          ),
          moz ms webkit
        );
      }
    }
  }

  .page {
    .split {
      overflow: hidden;

      &.essence {
        & > .h1 {
          & > svg {
            opacity: 0.8; // 1
          }
        }

        // &:before{
        //     top: 0;
        //     background-position: 50% 0%;
        //     background-image: url("#{$img}/soul-l.svg");
        //     @include prefix((
        //         transform: translateY(50%)
        //     ), webkit ms);
        // }
      }

      &.craft {
        & > .h1 {
          & > svg {
            opacity: 1;
          }
        }

        .pressRelease {
          bottom: 0;
          color: #fff;
          font-size: 9px;
          margin: 0 auto;
          max-width: 200px;
          padding-top: 30px;
          text-align: center;
          position: relative;
          font-family: $pFont;
          line-height: em(12px, 9px);
          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        // &:before {
        //     bottom: 0;
        //     background-position: 50% 100%;
        //     background-image: url("#{$img}/soul-d.svg");
        //     @include prefix((
        //         transform: translateY(-50%)
        //     ), webkit ms);
        // }
      }
    }
  }
}

.page {
  &.home {
    overflow: hidden;
  }
}
