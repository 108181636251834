@mixin contact {
  &:after,
  &:before {
    content: " ";
    margin: 0 auto;
    position: absolute;

    @include prefix(
      (
        transition: background ease-out 1.2s,
        transition-delay: 0.8s,
      ),
      moz ms webkit
    );
  }

  &:before {
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 50% 50% 0 50%;
    background: map-get($essence, color);

    @include prefix(
      (
        transform: rotate(45deg),
      ),
      webkit ms
    );
  }

  &:after {
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: map-get($craft, color);
  }
}

// body {
//   &.ESSENCE {
//     header.header {
//       @include linear-gradient(map-get($essence, colorL), rgba(map-get($essence, colorL), 0));
//     }
//   }
//   &.CRAFT {
//     header.header {
//       @include linear-gradient(map-get($craft, colorL), rgba(map-get($craft, colorL), 0));
//     }
//   }
// }

.barba-container {
  position: relative;
}

.pageNav {
  pointer-events: none !important;
}

.animated {
  .pageNav:not(.btn) {
    pointer-events: all !important;
  }

  .btn {
    pointer-events: none !important;

    &.animated {
      pointer-events: all !important;
    }
  }

  .page {
    .split {
      overflow: hidden;

      &.essence {
        & > .h1 {
          & > svg {
            opacity: 1;
          }
        }

        .dodecahedron {
          bottom: 0;
        }
      }

      &.craft {
        & > .h1 {
          & > svg {
            opacity: 1;
          }
        }

        .dodecahedron {
          top: 0;

          svg {
            top: -100%;
            background-position: 50% 100%;

            @include prefix(
              (
                transform: translateY(-50%),
              ),
              webkit ms
            );
          }
        }
      }
    }

    .dodecahedron {
      width: 100%;
      overflow: hidden;
      position: absolute;
      height: 100% !important;

      svg {
        left: -50%;
        position: relative;
        width: 200% !important;
        height: 200% !important;
      }
    }
  }
}

.lettering {
  position: relative;

  @include clearfix;

  span {
    position: relative;
    display: inline-block;

    &[class*="word"] {
      &:after {
        // content: " ";
        white-space: pre;
      }
    }
  }
}

.link {
  @include prefix(
    (
      transition-duration: 0.21s,
    ),
    moz ms webkit
  );

  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    letter-spacing: 2px;
  }
}

.btn,
button {
  border: none;
  cursor: pointer;
  color: $grey-alt;
  background: none;
  padding: 20px 40px;
  position: relative;
  font-size: em(21px);
  letter-spacing: 3px;
  // margin-bottom: 63px;
  margin-bottom: 83px;
  font-family: $fontL;
  white-space: initial;
  font-weight: regular;
  line-height: rem(31px);
  max-width: calc(21 * 21px);
  min-width: calc(21 * 14px);
  min-height: calc(21 * 3px);
  text-transform: uppercase;

  // line-height: calc(21*1.5px);
  @include prefix(
    (
      transition: 1s ease-out 1s,
    ),
    webkit ms
  );

  &:after,
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border: 1px solid $grey-alt;

    @include prefix(
      (
        transition: 0.4s ease-out,
      ),
      webkit ms
    );
  }

  &:after {
    @include prefix(
      (
        transform: translate(3px, 3px),
      ),
      webkit ms
    );
  }

  &:before {
    @include prefix(
      (
        transform: translate(-3px, -3px),
      ),
      webkit ms
    );
  }

  &:hover {
    background: $paper-dark-21;

    @include box-shadow(0px 0px 21px 21px rgba($paper-42, 0.12));
    @include prefix(
      (
        transition: 1s ease-out 0.4s,
      ),
      webkit ms
    );

    &:after,
    &:before {
      @include prefix(
        (
          transform: translate(0),
        ),
        webkit ms
      );
    }
  }
}

.page {
  padding: 2.5em 1.5em 2.5em 1.5em;
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom)
    constant(safe-area-inset-left);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  position: fixed;
  min-height: 100vh;
  padding-top: 105px;
  pointer-events: none;
  background: transparent;
  overflow: scroll; // /* has to be scroll, not auto */
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @extend %animateFast;

  @include linear-gradient(map-get($essence, colorL), map-get($essence, colorL));

  &:not(.flat) {
    @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
  }

  // &, .content {
  //     @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
  // }
  & > * {
    opacity: 0;
  }

  &:not(.animating) {
    visibility: hidden;
  }

  &.animated,
  &.active {
    height: 0;
    opacity: 1;
    z-index: 20;

    // overflow: hidden;
    pointer-events: all;
    visibility: visible;

    & > * {
      opacity: 1;
    }

    &.multiple {
      overflow-y: auto;

      .content {
        overflow: hidden;
      }
    }
  }

  .iPage {
    z-index: 10;
    margin-top: 36%;
    min-height: 100%;
    position: relative;
    padding: 0% 0px 0px;
    width: calc(100% - 20px);

    .textBlock {
      text-align: left;

      .h2 {
        margin-bottom: 30px;
      }

      .h3 {
        margin-bottom: 28.5px;
      }

      p {
        margin-bottom: 34px;
      }
    }
  }

  .scrollDown {
    left: 0;
    right: 0;
    top: 100vh;
    z-index: 10;
    height: 150px;
    margin: 0 auto;
    position: absolute;
    margin-top: -200px !important;

    @extend %animateFast;

    & > svg {
      width: 25px;
    }

    // &:hover, &:focus, &:active {
    //     margin-top: -150px !important;
    // }
  }

  & > canvas {
    top: 0;
    left: 0;
    z-index: 20;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
  }

  .content,
  .layer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .layer {
    z-index: 10;

    &.z-top {
      z-index: 1050;
    }

    &.z-bottom {
      z-index: 10;
    }

    &.z-middle {
      z-index: 600;
    }
  }

  .content {
    height: auto;
    z-index: 100;
    min-height: 100%;
    position: relative;
    text-align: center;
    padding: 0px 21px 42px;

    & > *:not(.iPage) {
      width: 98%;
      margin: 0 auto;
      max-width: 540px;
    }

    .btn,
    .interactive {
      pointer-events: all;
    }

    .element {
      max-height: 100%;
      pointer-events: none;
    }

    & > figure {
      height: 300px;
      margin-top: 35px;

      img[src=""] {
        opacity: 0;
      }
    }

    .btn,
    .sign,
    .element,
    .scrollDown,
    .animatedText,
    blockquote {
      opacity: 0;

      // visibility: hidden;
      &.animated,
      &.slideInLeft,
      &.slideInRight,
      &.bounceIn,
      &.pulse {
        opacity: 1;

        // visibility: visible;
      }
    }

    blockquote {
      padding: 10px;
      margin: 0 auto;
      border: 0 none;
      text-align: left;
      position: relative;
      text-indent: -15px;

      & > span {
        text-indent: initial;
      }

      &:before {
        content: " ";
        position: absolute;
      }
    }

    .sign {
      margin-top: 42px;
      text-align: right;
      margin-bottom: 63px;

      &.signed-mark {
        & > svg {
          max-width: 230px;
        }
      }
    }
  }

  .split {
    left: 0;
    right: 0;
    height: 50vh;
    position: absolute;

    @include prefix(
      (
        transition-duration: 2.1s,
      ),
      moz ms webkit
    );

    & > .h1 {
      width: 100%;
      height: 100px;
      text-align: center;
      position: absolute;
      pointer-events: none;

      // background: url('#{$img}/logo.svg') center center no-repeat;
      // background-size: contain;
      & > svg {
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        max-width: 815px;
        position: absolute;
        height: auto !important;
        width: calc(100% - 30px);

        @include prefix(
          (
            transition: all ease-in 0.5s,
          ),
          moz ms webkit
        );
      }
    }

    .link {
      left: 50%;
      width: 80px;
      height: 40%;
      min-height: 180px;
      margin-left: -40px;
      position: absolute;
      font-family: $pFontB;

      .text,
      .animate {
        display: block;
        position: absolute;
      }

      .animate {
        width: 100%;
        height: 100%;

        @include animation("pulse 1s infinite");

        img {
          margin: auto;
          display: block;

          @extend %animateFast;

          @include prefix(
            (
              transform: translateY(50%),
            ),
            webkit ms
          );
        }
      }

      .text {
        left: 50%;
        height: 2em;
        width: 150px;
        font-size: 15px;
        overflow: hidden;
        line-height: 2em;
        text-align: center;

        @include prefix(
          (
            transition-duration: 0.21s,
          ),
          moz ms webkit
        );
      }

      &:hover,
      &:focus,
      &:active {
        .text {
          font-size: 16px;
          letter-spacing: 3px;
        }

        // .animate {
        //     @include animation('bounce 1s infinite');
        // }
      }
    }

    &.essence {
      top: 0;

      @include linear-gradient(map-get($essence, colorL), map-get($essence, color));

      & > .h1 {
        bottom: 0;
      }

      .link {
        top: 0px;

        @media only screen and (max-width: 768px), (max-height: 812px) {
          top: 40px;
        }

        color: map-get($craft, color);

        .text {
          top: 150px;
          text-align: left;

          @include prefix(
            (
              transform: rotate(-90deg),
              transform-origin: 0% 50%,
            ),
            webkit ms
          );
        }

        &:hover,
        &:focus,
        &:active {
          img {
            @include prefix(
              (
                transform: scale(0.8) translateY(40%),
              ),
              webkit ms
            );
          }
        }
      }

      .fill-theme {
        fill: #fff;
      }

      .dodecahedron {
        bottom: 0;
      }
    }

    &.craft {
      bottom: 0;
      opacity: 0.95;

      @include linear-gradient(map-get($craft, colorL), map-get($craft, color));

      & > .h1 {
        top: 1px;
        pointer-events: none;

        @include prefix(
          (
            transform: scaleY(-1),
          ),
          webkit ms
        );

        & > svg {
          opacity: 0;
        }
      }

      .dodecahedron {
        top: 0;

        svg {
          top: -100%;
          background-position: 50% 100%;

          @include prefix(
            (
              transform: translateY(-50%),
            ),
            webkit ms
          );
        }
      }

      .link {
        bottom: 0px;

        @media only screen and (max-width: 768px), (max-height: 812px) {
          bottom: 40px;
        }

        color: map-get($craft, text);

        .text {
          bottom: 0px;
          text-align: right;

          @include prefix(
            (
              transform: rotate(-90deg),
              transform-origin: 0% 50%,
            ),
            webkit ms
          );
        }

        &:hover,
        &:focus,
        &:active {
          img {
            @include prefix(
              (
                transform: scale(0.8) translateY(70%),
              ),
              webkit ms
            );
          }
        }
      }

      .fill-theme {
        fill: #000;
      }
    }
  }

  .dodecahedron {
    width: 100%;
    height: 100% !important;
    pointer-events: none;
    position: absolute;
    overflow: hidden;

    svg {
      left: -50%;
      position: relative;
      width: 200% !important;
      height: 200% !important;
    }
  }

  &.essence {
    @include linear-gradient(map-get($essence, colorL), map-get($essence, colorL));

    &:not(.flat) {
      @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
    }

    // &, .content {
    //     @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
    // }
    svg {
      .st0,
      .st1 {
        stroke: map-get($essence, header);
        stroke-width: 0.63;
      }

      .st0 {
        fill-opacity: 0.21;
        fill: map-get($essence, color);
      }

      .st1 {
        fill: none;
      }

      .st2 {
        fill: map-get($essence, header);
      }
    }
  }

  &.craft {
    @include linear-gradient(map-get($craft, color), map-get($craft, color));

    &:not(.flat) {
      @include linear-gradient(map-get($craft, colorL), map-get($craft, color));
    }

    // &, .content {
    // }
    svg {
      .st0,
      .st1 {
        stroke: map-get($theme, header-text);
        stroke-width: 0.63;
      }

      .st0 {
        fill-opacity: 0.21;
        fill: rgba(map-get($craft, text), 0.21);
      }

      .st1 {
        fill: none;
      }

      .st2 {
        fill: map-get($theme, header-text);
      }
    }
  }
}

.safety-link {
  font-family: $pFont;
  margin-bottom: 42px;

  a {
    text-decoration: underline;
  }
}

.branchLogo {
  display: block;
  max-width: 260px;
  margin: 60px auto 15px;
}

.locList {
  @include notaList;
  li {
    position: relative;
    padding-left: 80px;
    &:before {
      top: 0;
      left: 0;
      content: "";
      width: 65px;
      height: 65px;
      position: absolute;
      background: url("#{$img}/gif/SoulDark.gif") center center/contain no-repeat;
    }
  }
}

// .HOME {
//     .page.active {
//         @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
//         @include animation('slideInDown 2.1s');
//     }
// }

.CRAFT,
.ESSENCE {
  canvas {
    max-width: calc(100% - 16px);
  }
}

.ESSENCE {
  // .page {
  //     &, .content {
  //         @include linear-gradient(map-get($essence, colorL), map-get($essence, color));
  //     }
  //     @include animation('slideOutDown 2.1s');
  //     .split {
  //         &.craft {
  //             height: 0;
  //             opacity: 0;
  //         }
  //         &.essence {
  //             height: 100%;
  //         }
  //     }
  //     &.active {
  //         @include animation('slideInDown 2.1s');
  //     }
  // }

  .tagline._essence {
    opacity: 1;
    visibility: visible;
  }

  .tagline._craft {
    opacity: 0 !important;
    visibility: hidden;
  }
}

.craft,
.CRAFT {
  color: map-get($craft, text);

  .btn,
  button {
    color: map-get($craft, text);

    @include prefix(
      (
        transition-timing-function: ease-in,
      ),
      webkit ms
    );

    &:after,
    &:before {
      border-color: map-get($craft, text);

      @include prefix(
        (
          transition-timing-function: ease-in,
        ),
        webkit ms
      );
    }

    &:hover {
      background: $not-black;

      @include box-shadow(0px 0px 21px 21px rgba($not-black-42, 0.12));

      &,
      &:after,
      &:before {
        @include prefix(
          (
            transition-timing-function: ease-in,
          ),
          webkit ms
        );
      }
    }
  }

  header.header {
    a {
      &.menu {
        & > div {
          &.contact {
            &:before {
              background: map-get($craft, color);
            }

            &:after {
              background: map-get($craft, el);
            }
          }
        }
      }
    }
  }

  // .page {
  //     &, .content {
  //         @include linear-gradient(map-get($craft, colorL), map-get($craft, color));
  //     }
  //     @include animation('slideOutUp 2.1s');
  //     .content {
  //         blockquote {
  //             color: map-get($craft, text);
  //         }
  //     }
  //     .split {
  //         &.essence {
  //             height: 0;
  //             opacity: 0;
  //         }
  //         &.craft {
  //             height: 100%;
  //         }
  //     }
  //     &.active {
  //         @include animation('slideInUp 2.1s');
  //     }
  // }
  .tagline._craft {
    opacity: 1;
    visibility: visible;
  }

  .tagline._essence {
    opacity: 0 !important;
    visibility: hidden;
  }
}

ul.menuList {
  @include notaList;

  font-size: em(21px);
  font-family: "robotocondensed light";

  .h1 {
    margin-top: 21px;
    font-family: inherit;
    line-height: rem(42px);
    font-size: em(21px, 10.5px);
  }

  li {
    // margin-bottom: 25px;

    a {
      display: block;
      line-height: em(42px, 21px);
    }

    span,
    img {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      padding: 0 21px;
    }

    img {
      max-width: 28px;
    }
  }

  @media only screen and (min-width: 768px) and (min-height: 800px) {
    li {
      img {
        @include prefix(
          (
            transform: scale(2),
          ),
          webkit ms
        );
      }
    }
  }

  @media only screen and (max-width: 767px), (max-height: 812px) {
    font-size: 1em;
    padding-top: 0px;
    .h1 {
      margin-top: 10px;
      font-size: 1.3em;
      text-align: left;
      line-height: 1.2em;
    }

    li {
      // margin-bottom: 5px;
      a {
        line-height: 1.2em;
      }
    }
  }

  // @media only screen and (min-width: 767px), (min-height: 800px) {
  //     font-size: em(21px);
  //     li {
  //         margin-bottom: 16px;
  //         span {
  //             padding: 0 15px;
  //         }
  //     }
  // }
}

.essence {
  ul.menuList {
    // margin-right: -21px;

    &,
    a {
      color: map-get($theme, link);
    }
  }
}

.craft {
  ul.menuList {
    // margin-left: -21px;

    &,
    a {
      // color: map-get($craft, text);
      color: map-get($theme, color);
    }
  }
}

.popupMenu {
  a:not(.brand) {
    text-indent: 0px;
    display: inline-block;

    @include prefix(
      (
        transition: all 0.84s ease-in-out,
      ),
      moz ms webkit
    );

    &:hover,
    &:focus,
    &:active {
      @include prefix(
        (
          transform: scale(1.21),
          transition-duration: 0.4s,
          transform-origin: center center,
        ),
        moz ms webkit
      );
    }

    &.contact {
      left: 30px;
      width: 40px;
      height: 50px;
      bottom: 50px;
      position: absolute;
    }
    &:not([data-hide]) {
      padding-right: 30px;
    }
  }

  .menubar {
    a {
      padding: 35px 30px;
    }
  }

  .menuList {
    a {
      &:hover,
      &:focus,
      &:active {
        text-indent: 21px;

        @include prefix(
          (
            transform: none,
            transition-duration: 0.4s,
            transform-origin: center center,
          ),
          moz ms webkit
        );
      }
    }
  }

  .split {
    & > .row {
      left: 0;
      right: 0;
      margin: 0;
      overflow: auto;
      max-height: 100%;
      position: absolute;
      overflow-x: hidden;

      // pointer-events: none;
      a,
      button {
        pointer-events: all;
      }

      .t {
        &-l {
          padding-right: 0;
        }

        &-r {
          padding-left: 0;
        }
      }
    }

    &.essence {
      padding-top: 105px;

      & > .row {
        bottom: 0;
      }

      ul.menuList {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    &.craft {
      & > .row {
        top: 0;
      }

      ul.menuList {
        padding-top: 24px;
      }
    }

    @media only screen and (max-width: 767px), (max-height: 800px) {
      &.essence {
        padding-top: 65px;
        ul.menuList {
          padding-top: 0px;
          padding-bottom: 14px;
        }
      }
      &.craft {
        ul.menuList {
          padding-top: 14px;
        }
      }

      // &.craft {
      //     ul.menuList {
      //         padding-top: 48px;
      //     }
      // }
    }
  }

  .contact {
    & > div {
      @include contact;
    }

    &:hover,
    &:focus,
    &:active {
      background: transparent;

      & > div {
        @include prefix(
          (
            transform-origin: center bottom,
          ),
          moz ms webkit
        );
      }
    }
  }
}

.template {
  speak: never;
  display: none;
  visibility: hidden;
  speak-as: spell-out;
}

.popup.popupText {
  border-radius: 3em;
  color: map-get($craft, text);
  @include linear-gradient(map-get($craft, colorL), map-get($craft, color));
  a.close {
    top: 0.5em;
    right: 15px;
    width: 42px;
    height: 42px;
    padding: 5px;
  }
  .title {
    font-size: 30px;
    font-family: $hFont;
  }
  .popHeader {
    min-height: 60px;
    padding: 1.5em 3em 1em;
    border-bottom: 1px solid rgba(#000, 0.16);
  }
  .popContent {
    padding: 3em;
  }
  a {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    .popHeader {
      padding-top: 1em;
      a.close {
        right: 12px;
        width: 1.5em;
        height: 2.4em;
        font-size: 1em;
      }
    }
  }
}

.privacy {
  font-size: 24px;
  max-width: 900px;
  line-height: 1.7em;
  text-align: center;
  font-family: $pFont;
  margin: 0 auto 1.7em;
  @media screen and (min-height: 768px) {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
  @media screen and (max-width: 767px) {
    font-size: 1.3em;
  }
}

.popup {
  &.popupWelcome {
    color: map-get($color, white);
    background: #000 url("#{$img}/real-chemistry/desktop/bubbles.png") right bottom no-repeat;
    background-size: contain;
    .popContent {
      padding: 50px 55px 0;
      @include scrollbar(8px, transparent, transparent);
    }
    &._fast {
      @include prefix(
        (
          animation-duration: 1.5s,
        ),
        webkit ms
      );
    }
    .close {
      top: 24px;
      right: 24px;
      width: 43px;
      height: 43px;
      display: block;
      font-size: 15px;
      line-height: 43px;
      position: absolute;
      text-align: center;
      border-radius: 50%;
      color: map-get($color, white);
      border: 2.5px solid map-get($color, white);
    }

    a {
      display: block;
      img {
        pointer-events: none;
      }
    }

    .learnMore {
      font-size: 24px;
      color: #878787;
      max-width: 360px;
      margin: 75px 0 55px;
      @extend %animateFast;
      background: transparent;
      border: 0 none transparent;
      @include font( 'HelveticaNowText-Bold');
      svg {
        width: 25px;
      }
      &,
      &:hover,
      &:focus,
      &:active {
        outline: 0 none transparent;
      }
      &:hover,
      &:focus,
      &:active {
        color: map-get($color, white);
      }
    }

    .mobile {
      display: none;
    }
    @media only screen and (max-width: 747px) {
      // background-color: #000;
      // background-image: url("#{$img}/real-chemistry/bg_mob.png");
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    }
  }
}
