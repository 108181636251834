// @if mixin-exists(clearfix) {
// 	@debug "@warn: Already clearfix mixin support Available";
// }

// @mixin clearfix() {
// 	&:before, &:after {
// 		content: " ";
// 		display: table;
// 	}
// 	&:after {
// 		clear: both;
// 	}
// }

@function em($px, $base: $baseFontSize) {
	@return ($px / $base) * 1em;
}
@function rem($px, $base: $baseFontSize) {
	@return ($px / $base) * 1rem;
}

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}

@mixin new-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: "#{$font-family}";
		src: url("#{$font-filename}.eot");
		src: url("#{$font-filename}.eot?#iefix") format("embedded-opentype"), url("#{$font-filename}.woff") format("woff"),
			url("#{$font-filename}.ttf") format("truetype"), url("#{$font-filename}.svg##{$font-family}") format("svg");
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}

@mixin font($font) {
	@if type-of($font) == number {
		font-weight: $font;
	} @else {
		font-family: $font;
	}
}

@mixin langFont($style: 400) {
	@if $style == "bold" {
		@include font($fontFamilyBold);
	} @else {
		@include font($fontFamily);
	}
}

// /* -------- ANIMATIONS -------- */
$vendor: "-moz-", "-webkit-", "-ms-", "";

///*@mixin saveAnimation($animateName:"animationName") {
//	@each $i in $vendor {
//		#{$i}keyframes #{$animateName} {
//			@content;
//		}
//	}
//}*/

@mixin prefix($declarations, $prefixes: ()) {
	@each $property, $value in $declarations {
		@each $prefix in $prefixes {
			#{'-' + $prefix + '-' + $property}: #{$value};
		}
		// Output standard non-prefixed declaration
		#{$property}: #{$value};
	}
} ///* [EG] @include prefix((column-count: 3,column-gap: 1.5em,column-rule: 2px solid hotpink), webkit moz);
//[copy]//  @include prefix(( X:X,X:X ), webkit moz);
//*/

@mixin animate($animateName, $duration: 0.3s, $loop: 1, $timing: ease-in) {
	@each $prefix in $vendor {
		#{$prefix}animation-name: $animateName;
		#{$prefix}animation-iteration-count: $loop;
		#{$prefix}animation-timing-function: $timing;
		#{$prefix}animation-duration: $duration;
	}
} ///*@include animate(slideUp, 0.3s, 1, ease-in);*/

@mixin border-radius($radius) {
	-moz-border-radius: #{$radius};
	-o-border-radius: #{$radius};
	-webkit-border-radius: #{$radius};
	border-radius: #{$radius};
}

// @mixin linear-gradient($fromColor, $toColor, $start:0%, $stop:100%) {
// 	background-color: $fromColor;
// 	background-image: -moz-linear-gradient(top, $fromColor $start, $toColor $stop);
// 	background-image: -webkit-gradient(linear, left top, left bottom, color-stop($start,$fromColor), color-stop($stop,$toColor));
// 	background-image: -webkit-linear-gradient(top, $fromColor $start, $toColor $stop);
// 	background-image: -o-linear-gradient(top, $fromColor $start, $toColor $stop);
// 	background-image: -ms-linear-gradient(top, $fromColor $start, $toColor $stop);
// 	background-image: linear-gradient(top, $fromColor $start, $toColor $stop);
// 	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
// }///*@include linear-gradient(#000, #fff[, 0%, 100%])*/
// @mixin h-gradient($fromColor, $toColor, $start:0%, $stop:100%) {
// 	background: $fromColor; /* Old browsers */
// 	background: -moz-linear-gradient(left, $fromColor $start, $toColor $stop); /* FF3.6+ */
// 	background: -webkit-gradient(linear, left top, right top, color-stop($start,$fromColor), color-stop($stop,$toColor)); /* Chrome,Safari4+ */
// 	background: -webkit-linear-gradient(left, $fromColor $start,$toColor $stop); /* Chrome10+,Safari5.1+ */
// 	background: -o-linear-gradient(left, $fromColor $start,$toColor $stop); /* Opera 11.10+ */
// 	background: -ms-linear-gradient(left, $fromColor $start,$toColor $stop); /* IE10+ */
// 	background: linear-gradient(to right, $fromColor $start,$toColor $stop); /* W3C */
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$fromColor}', endColorstr='#{$toColor}',GradientType=1 ); /* IE6-9 */
// }

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool} - return boolean
@function is-direction($value) {
	$is-keyword: index(
		(
			to top,
			to top right,
			to right top,
			to right,
			to bottom right,
			to right bottom,
			to bottom,
			to bottom left,
			to left bottom,
			to left,
			to left top,
			to top left
		),
		$value
	);
	$is-angle: type-of($value) == "number" and index("deg" "grad" "turn" "rad", unit($value));

	@return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
	@if is-direction($value) == false {
		@error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
	}

	$conversion-map: (
		to top: bottom,
		to top right: bottom left,
		to right top: left bottom,
		to right: left,
		to bottom right: top left,
		to right bottom: left top,
		to bottom: top,
		to bottom left: top right,
		to left bottom: right top,
		to left: right,
		to left top: right bottom,
		to top left: bottom right
	);

	@if map-has-key($conversion-map, $value) {
		@return map-get($conversion-map, $value);
	}

	@return 90deg - $value;
}

@mixin linear-gradient($direction, $color-stops...) {
	// Direction has been omitted and happens to be a color-stop
	@if is-direction($direction) == false {
		$color-stops: $direction, $color-stops;
		$direction: 180deg;
	}
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}
// Examples
// @include linear-gradient(#31B7D7, #EDAC7D);
// @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
// @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);

@mixin strippedBG($deg, $start, $sdist, $end, $edist) {
	background: -webkit-repeating-linear-gradient($deg, $start, $start $sdist, $end $sdist, $end $edist);
	background: -moz-repeating-linear-gradient($deg, $start, $start $sdist, $end $sdist, $end $edist);
	background: repeating-linear-gradient($deg, $start, $start $sdist, $end $sdist, $end $edist);
} ///*@include strippedBG(90deg, #2B2B2B, 2px, #323232, 4px);*/

@mixin box-shadow($arguments) {
	-moz-box-shadow: #{$arguments};
	-o-box-shadow: #{$arguments};
	-webkit-box-shadow: #{$arguments};
	box-shadow: #{$arguments};
} ///*@include box-shadow(inset -3px -2px 6px 0px rgba(0, 0, 0, 0.3));*/
@mixin opacity($val) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$val*100)";
	filter: alpha(opacity=$val * 100);
	-o-opacity: $val;
	-moz-opacity: $val;
	-khtml-opacity: $val;
	opacity: $val;
}

@mixin userSelect($val) {
	-webkit-touch-callout: $val;
	-webkit-user-select: $val;
	-khtml-user-select: $val;
	-moz-user-select: $val;
	-ms-user-select: $val;
	user-select: $val;
}

@mixin transform($val) {
	-webkit-transform: $val;
	-o-transform: $val;
	-ms-transform: $val;
	transform: $val;
}

@mixin transition($val) {
	transition: #{$val};
	-webkit-transition: #{$val};
	-moz-transition: #{$val};
	-o-transition: #{$val};
}

@mixin calc($op, $mgn: 0, $pdg: $mgn, $width: 100%) {
	@if $op== "-" {
		margin: 0 -#{$mgn};
		padding: 0 #{$pdg};
		width: #{$width};
	} @else {
		margin: 0 #{$mgn};
		padding: 0 #{$pdg};
		width: #{$width};
	}
}

///*Flex*/
@mixin flex() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
@mixin flex($values) {
	-webkit-box-flex: $values;
	-moz-box-flex: $values;
	-webkit-flex: $values;
	-ms-flex: $values;
	flex: $values;
}
@mixin order($val) {
	-webkit-box-ordinal-group: $val;
	-moz-box-ordinal-group: $val;
	-ms-flex-order: $val;
	-webkit-order: $val;
	order: $val;
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@function em($px, $base: $baseFontSize) {
	@return ($px / $base) * 1em;
}
@function rem($px, $base: $baseFontSize) {
	@return ($px / $base) * 1rem;
}

@mixin animation($animate...) {
	$max: length($animate);
	$animations: "";

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	@include prefix(
		(
			animation: $animations,
		),
		webkit moz o
	);
} // @include animation('animationName 1s 1'[, 'animationName2 1s 1 1s']);

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
} //@include keyframes(animationName) {0% { x: x; }100% { x: x;} }

@mixin placeholder {
	@include optional-at-root("::-webkit-input-placeholder") {
		@content;
	}

	@include optional-at-root(":-moz-placeholder") {
		@content;
	}

	@include optional-at-root("::-moz-placeholder") {
		@content;
	}

	@include optional-at-root(":-ms-input-placeholder") {
		@content;
	}
}

// @include scrollbar(.5em, slategray);
@mixin scrollbar($size, $fgColor, $bgColor: mix($fgColor, white, 50%)) {
	// For Google Chrome
	&::-webkit-scrollbar {
		width: $size;
		height: $size;
		// /* Handle */
		&-thumb {
			border-radius: 4px;
			background: $fgColor;
			// /* Handle on hover */
			&:hover {
				background: darken($fgColor, 6.5%);
			}
		}
		// /* Track */
		&-track {
			margin: 0 4px;
			border-radius: 4px;
			background: $bgColor;
		}
	}

	// IE
	scrollbar-face-color: $fgColor;
	scrollbar-track-color: $bgColor;

	// Mozilla Firefox
	scrollbar-width: thin;
	scrollbar-color: $fgColor $bgColor;
}

@import "flex";

///*Site based Mixins*/
