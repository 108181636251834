$tags: "m" "margin", "p" "padding";
$space: 5;
$sides: "a" "", "t" "-top", "r" "-right", "b" "-bottom", "l" "-left", "x" "-left" "-right", "y" "-top" "-bottom";

.bg {
	@each $type in $bgColor {
		&-#{nth($type, 1)} {
			color: nth($type, 3) !important;
			background: nth($type, 2) !important;
		}
	}
}

.text {
	@each $type in $colorType {
		&-#{nth($type, 1)} {
			color: nth($type, 2) !important;
			a,
			label {
				color: nth($type, 2) !important;
			}
		}
	}
}

.fill {
	@each $type in $colorType {
		&-#{nth($type, 1)} {
			fill: nth($type, 2);
		}
	}
}

.t {
	&-l {
		text-align: left;
	}
	&-r {
		text-align: right;
	}
	&-c {
		text-align: center;
	}
	&-j {
		text-align: justify;
	}
}

.ib {
	vertical-align: middle;
	&,
	&-t,
	&-m,
	&-b,
	&-l {
		display: inline-block;
	}
	&-t {
		vertical-align: top;
	}
	&-m {
		vertical-align: middle;
	}
	&-b {
		vertical-align: bottom;
	}
	&-l {
		vertical-align: baseline;
	}
}

.i {
	display: inline-block;
	&-stack {
		width: 2em;
		height: 2em;
		line-height: 2em;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		&-1x,
		&-2x {
			left: 0;
			width: 100%;
			text-align: center;
			position: absolute;
		}
		&-1x {
			line-height: inherit;
		}
		&-2x {
			font-size: 2em;
		}
	}
	&-0_3x {
		font-size: 0.3em;
	}
	&-0_5x {
		font-size: 0.5em;
	}
	&-0_7x {
		font-size: 0.7em;
	}
	&-1_3x {
		font-size: 1.3em;
	}
	&-1_5x {
		font-size: 1.5em;
	}
	&-1_7x {
		font-size: 1.7em;
	}
	&-2x {
		font-size: 2em;
	}
	&-2_3x {
		font-size: 2.3em;
	}
	&-2_5x {
		font-size: 2.5em;
	}
	&-2_7x {
		font-size: 2.7em;
	}
	&-3x {
		font-size: 3em;
	}
	&-3_3x {
		font-size: 3.3em;
	}
	&-3_5x {
		font-size: 3.5em;
	}
	&-3_7x {
		font-size: 3.7em;
	}
	&-4x {
		font-size: 4em;
	}
	&-4_3x {
		font-size: 4.3em;
	}
	&-4_5x {
		font-size: 4.5em;
	}
	&-4_7x {
		font-size: 4.7em;
	}
	&-5x {
		font-size: 5em;
	}
	&-rotate-90 {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
		@include prefix(
			(
				transform: rotate(90deg),
			),
			webkit ms
		);
	}
	&-rotate-180 {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
		@include prefix(
			(
				transform: rotate(180deg),
			),
			webkit ms
		);
	}
	&-rotate-270 {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
		@include prefix(
			(
				transform: rotate(270deg),
			),
			webkit ms
		);
	}
	&-flip-horizontal {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
		@include prefix(
			(
				transform: scale(-1, 1),
			),
			webkit ms
		);
	}
	&-flip-vertical {
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
		@include prefix(
			(
				transform: scale(1, -1),
			),
			webkit ms
		);
	}
}

.m {
	&-x-auto {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	&-y-auto {
		margin-top: auto !important;
		margin-left: auto !important;
	}
}

.w-auto {
	width: auto !important;
	&.min {
		min-width: initial;
	}
	&.max {
		max-width: initial;
	}
}

.h-auto {
	height: auto !important;
	&.min {
		min-height: initial;
	}
	&.max {
		max-height: initial;
	}
}

@each $tag in $tags {
	@each $side in $sides {
		@for $i from 0 through 6 {
			.#{nth($tag,1)}-#{nth($side,1)}-#{$i * $space} {
				@if nth($side, 1) == "x" or nth($side, 1) == "y" {
					#{nth($tag,2)}#{nth($side,2)}: #{$i * $space}px !important;
					#{nth($tag,2)}#{nth($side,3)}: #{$i * $space}px !important;
				} @else {
					#{nth($tag,2)}#{nth($side,2)}: #{$i * $space}px !important;
				}
			}
		}
	}
}

// /* Negative Margin */
@each $side in $sides {
	$tag: "margin";
	@for $i from 1 through 6 {
		.m-#{nth($side,1)}--#{$i * $space} {
			@if nth($side, 1) == "x" or nth($side, 1) == "y" {
				#{$tag}#{nth($side,2)}: #{$i * -$space}px !important;
				#{$tag}#{nth($side,3)}: #{$i * -$space}px !important;
			} @else {
				#{$tag}#{nth($side,2)}: #{$i * -$space}px !important;
			}
		}
	}
}

// Borders
[class*=" b-"],
[class^="b-"] {
	border-width: 0px;
	border-color: $Brdr;
	border-style: solid;
	&.solid {
		border-style: solid;
	}
	&.dashed {
		border-style: dashed;
	}
	&.dotted {
		border-style: dotted;
	}
	&.double {
		border-style: double;
	}
}

@each $side in $sides {
	$tag: "border";
	@for $i from 0 through 5 {
		.b-#{nth($side,1)}-#{$i} {
			@if nth($side, 1) == "x" or nth($side, 1) == "y" {
				#{$tag}#{nth($side,2)}-width: #{$i}px !important;
				#{$tag}#{nth($side,3)}-width: #{$i}px !important;
			} @else {
				#{$tag}#{nth($side,2)}-width: #{$i}px !important;
			}
		}
	}
	@each $type in $colorType {
		.b-#{nth($side,1)}-#{nth($type,1)} {
			@if nth($side, 1) == "x" or nth($side, 1) == "y" {
				#{$tag}#{nth($side,2)}-color: nth($type, 2) !important;
				#{$tag}#{nth($side,3)}-color: nth($type, 2) !important;
			} @else {
				#{$tag}#{nth($side,2)}-color: nth($type, 2) !important;
			}
		}
	}
}

// Width and Height
@for $i from 1 through 20 {
	@if $i <= 16 {
		.w-#{$i*20} {
			width: #{$i * 20}px !important;
			&.min,
			&.max {
				width: auto !important;
			}
			&.min {
				min-width: #{$i * 20}px !important;
				overflow: visible;
			}
			&.max {
				max-width: #{$i * 20}px !important;
			}
			&.scroll {
				overflow: auto;
				&-x {
					overflow-y: hidden;
				}
				&-y {
					overflow-x: hidden;
				}
			}
		}
	}

	$h: $i * 40;
	.h-#{$h} {
		overflow: hidden;
		height: #{$h}px !important;
		&.min,
		&.max {
			height: auto !important;
		}
		&.min {
			min-height: #{$h}px !important;
			overflow: visible;
		}
		&.max {
			max-height: #{$h}px !important;
		}
		&.scroll {
			overflow: auto;
			&-x {
				overflow-y: hidden;
			}
			&-y {
				overflow-x: hidden;
			}
		}
	}
}

$layout: "N" "Navigation", "T" "Toolbar";

.c {
	@include clearfix();
}

.r {
	@extend .row !optional;
}

.L {
	float: left;
}
.R {
	float: right;
}

.bar {
	background: #fff;
	@include clearfix();
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		& > span {
			font-size: 75%;
			color: rgba(#000, 0.5);
		}
	}
	.L,
	.R {
		& > a {
			display: inline-block;
		}
	}
	&.transparent {
		background: transparent;
	}

	@each $bar in $layout {
		&-#{nth($bar,1)} {
			/* bar: #{nth($bar,2)} */
			@extend .bar !optional;
		}
	}

	// &-T {
	// }
}
