.page {
  &#essence {
    .btn {
      line-height: 2em;
      margin-bottom: 30px;
    }
  }

  &#craft {
    .sign {
      margin-top: 21px;
      margin-bottom: 42px;
    }

    .btn {
      letter-spacing: 0;
    }
  }

  &#love,
  &#emotion,
  &#insight,
  &#leadership,
  &#casestudy,
  &#dedication,
  &#legend,
  &#inspiration {
    .iPage {
      .textBlock {
        z-index: 0;
        position: relative;
        padding-bottom: 2%;
      }
    }

    .element {
      opacity: 0;
      z-index: -1;
      background-size: contain;

      // background-size: 68%;
      background-repeat: no-repeat;

      &.slideInLeft,
      &.slideInRight {
        opacity: 0.4;
      }
    }
  }

  &#inspiration {
    .content {
      & > .h2 {
        font-family: map-get($theme, fontL);
      }
    }

    .element {
      top: auto;
      left: auto;
      right: -40px;
      bottom: -60px;
      height: 975px;
      width: 821px;
      background-position: right bottom;
      background-image: url("#{$img}/inspirationFill.svg");

      &._2 {
        background-image: url("#{$img}/inspirationLine.svg");
      }
    }
  }

  &#legend {
    .element {
      top: auto;
      right: auto;
      left: -20px;
      bottom: -60px;
      width: 1024px;
      max-width: 50%;
      height: 1197px;
      background-position: left bottom;
      background-image: url("#{$img}/legendaryFill.svg");

      &._2 {
        background-image: url("#{$img}/legendaryLine.svg");
      }
    }
  }

  &#emotion {
    .element {
      top: auto;
      left: auto;
      right: -40px;
      bottom: -60px;
      width: 100%;
      max-width: 50%;
      width: 920px;
      height: 1257px;
      background-position: right bottom;
      background-image: url("#{$img}/emotionFill.svg");

      &._2 {
        background-image: url("#{$img}/emotionLine.svg");
      }
    }
  }

  &#love {
    .element {
      top: auto;
      left: auto;
      width: 100%;
      right: -40px;
      bottom: -60px;
      opacity: 0.21;
      height: 1122px;
      max-width: 50%;
      width: 1024px;
      background-position: right bottom;
      background-image: url("#{$img}/loveFill.svg");

      &._2 {
        opacity: 0.63;
        background-image: url("#{$img}/loveLine.svg");
      }
    }
  }

  &#dedication {
    .element {
      top: auto;
      right: auto;
      left: -20px;
      bottom: -60px;
      max-width: 50%;
      width: 1024px;
      height: 1148px;
      background-position: left bottom;
      background-image: url("#{$img}/dedicationFill.svg");

      &._2 {
        background-image: url("#{$img}/dedicationLine.svg");
      }
    }
  }

  &#insight {
    .iPage {
      height: auto;

      .col-md-4 {
        // padding-bottom: 10%;
      }
    }

    .element {
      top: auto;
      left: auto;
      right: -40px;
      bottom: -60px;
      background-position: right bottom;
      background-image: url("#{$img}/insightFill.svg");

      &._2 {
        background-image: url("#{$img}/insightLine.svg");
      }
    }
  }

  &#leadership {
    .element {
      top: -430px;
      right: auto;
      left: -20px;
      bottom: auto;
      max-width: 50%;
      width: 1024px;
      height: 1148px;
      background-position: left bottom;
      background-image: url("#{$img}/leadershipFill.svg");

      &._2 {
        top: -110px;
        background-image: url("#{$img}/leadershipLine.svg");
      }

      &._3,
      &._4 {
        @include prefix(
          (
            transform: scale(-1, 1)
          ),
          webkit ms
        );
      }

      &._3 {
        top: auto;
        left: auto;
        right: -40px;
        bottom: 840px;
        background-image: url("#{$img}/leadershipFill.svg");
      }

      &._4 {
        top: auto;
        left: auto;
        right: -40px;
        bottom: 840px;
        background-image: url("#{$img}/leadershipLine.svg");
      }
    }

    picture {
      display: block;
      margin: 0 auto;
      max-width: 294px;
      max-height: 440px;
      margin-bottom: 34px;
      border: map-get($theme, brdr) 1px solid;
    }

    .iPage {
      &._2 {
        .row {
          margin-bottom: 72px;
        }
      }
    }
  }

  &#casestudy {
    .iPage {
      margin-top: 0;
      padding-top: 0;
    }

    .element {
      top: auto;
      right: auto;
      left: -20px;
      bottom: -60px;
      width: 1024px;
      height: 1090px;
      max-width: 50%;
      background-position: left bottom;
      background-image: url("#{$img}/casestudyFill.svg");

      &._2 {
        background-image: url("#{$img}/casestudyLine.svg");
      }
    }

    picture {
      margin: 0 auto;
      display: block;
      max-width: 294px;
      max-height: 440px;
      margin-bottom: 106px;
      border: $paper-darker 1px solid;
    }
  }
}

#contact,
#popupContact {
  .row,
  .content {
    padding: 0;
    margin: 0 auto;
    max-width: 1274px;
  }

  p {
    margin-bottom: 3.32%;
  }

  .h1 {
    font-family: map-get($theme, fontL);
  }

  .h2 {
    max-width: 608px;
    margin: 0 auto 10%;
    text-align: center;
  }

  .address {
    font-size: em(21px);
    margin-bottom: 4.6%;
    font-family: map-get($theme, hFont);
    &.sm {
      font-size: 1em;
      line-height: 1.4em;
      font-family: map-get($theme, pFont);
    }
  }
}
