.popup {
  canvas {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  // .popContent,
  .popContent {
    z-index: 10;
    position: relative;
  }

  .popHeader {
    padding: 0;
    border: 0 none;
    font-size: inherit;

    .close {
      background: transparent;
    }

    .menubar {
      li {
        a {
          line-height: 105px;
          font-size: em(42px, 14px);
        }
      }
    }
  }

  &.craft {
    @include linear-gradient(map-get($craft, colorL), map-get($craft, color));
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page {
    .iPage {
      padding: 0px;
      margin-top: 60vh;
    }

    .scrollDown {
      margin-top: -260px !important;
    }

    .element {
      opacity: 0.21 !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  picture {
    display: block;
    text-align: center;
  }

  .mx-sm-auto {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .branchLogo {
    max-width: 180px;
    // margin: 60px auto 15px;
  }

  .locList {
    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      text-align: left;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    text-align: center;
  }

  .page .iPage {
    .textBlock {
      text-align: center !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4 {
    font-size: em(21px, 14px);
    line-height: rem(31px, 14px);
  }

  h3,
  .h3 {
    // text-align: left;
    letter-spacing: 0.4px;
  }

  // h4,
  // .h4 {
  //   text-align: left;
  // }

  h5,
  .h5 {
    line-height: 1.4em;
    font-size: em(16px, 14px);
  }

  h6,
  .h6 {
    line-height: 1.4em;
    font-size: em(14px, 14px);
  }

  p {
    text-align: left;
    letter-spacing: -1px;
    font-size: em(14px, 14px);
    line-height: rem(21px, 14px);
  }

  .element {
    height: 80% !important;

    &._2 {
      display: none;
    }
  }

  blockquote {
    line-height: 21px;
    font-size: em(18px, 14px);
  }

  .btn,
  button {
    padding: 18px 20px;
    font-size: em(12px, 14px);

    // left: 50%;
    // @include prefix((
    //   transform: translateX(-50%)
    // ), webkit ms);
  }

  .popHeader,
  header.header {
    height: 63px;

    a.brand {
      width: 184px;
      padding: 20px 0 20px 20px;

      .tagline {
        display: none;
      }
    }

    a.menu {
      width: 63px;
      height: 63px;

      & > div.contact {
        width: 21px;
        height: 24px;

        &:before {
          width: 21px;
          height: 21px;
        }

        &:after {
          top: 3px;
          left: 3px;
          width: 15px;
          height: 15px;
        }
      }

      &:hover,
      &:focus,
      &:active {
        & > div.contact {
          &:after {
            top: 3px;
            left: 3px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .page {
    width: 100%;
    padding-top: 63px;

    &.animated,
    &.active {
      &.multiple {
        overflow-y: auto;
      }
    }

    .split {
      .dodecahedron {
        // left: -10px;
        svg {
          left: 0%;
          width: 100% !important;
        }
      }

      & > .h1 {
        // left: -10px;
        & > svg {
          // max-width: calc(100% - 50px);
          max-width: 66%;
        }
      }
    }

    .iPage {
      margin-top: 68vh;
    }

    .content {
      padding: 0px 12px 21px;

      blockquote {
        text-align: center;
        text-indent: -7px;
      }

      .btn {
        min-width: auto;
        max-width: 280px;
        margin-bottom: 90px;
      }

      .sign {
        margin: 21px 0 !important;

        &.signed-mark > svg {
          max-width: 132px;
        }
      }

      & > figure {
        height: 160px;
        width: 160px !important;
      }
    }

    .scrollDown {
      & > svg {
        height: 90% !important;

        // margin-top: -140px !important;
      }
    }

    &#leadership {
      picture {
        margin-bottom: 33.5px;
      }

      .iPage {
        &._2 {
          .row {
            margin-bottom: 35px;
          }
        }
      }
    }
  }

  .popup {
    .popHeader {
      font-size: em(18px, 14px);

      a:not(.brand) {
        width: 63px;
        height: 63px;
      }

      .menubar {
        li {
          a {
            font-size: 1em;
            line-height: 63px;
          }
        }
      }
    }
  }

  .ESSENCE {
    header.header {
      // background: #fbf8ed;
      background: map-get($essence, heading);
    }
  }

  .CRAFT {
    header.header {
      // background: #484847;
      background: map-get($craft, heading);
    }
  }

  .HOME {
    header.header {
      background: transparent;
    }
  }
}
