/*animations Page <--BEGINS */
@import "../plugins/animate";
.animated {
	&._fast {
		@include prefix(
			(
				animation-duration: 0.21s,
			),
			webkit ms
		);
	}
	&._1s {
		@include prefix(
			(
				animation-duration: 1s,
			),
			webkit ms
		);
	}
	&._1-5s {
		@include prefix(
			(
				animation-duration: 1.5s,
			),
			webkit ms
		);
	}
	&._2s {
		@include prefix(
			(
				animation-duration: 2.1s,
			),
			webkit ms
		);
	}
	&._2-5s {
		@include prefix(
			(
				animation-duration: 2.5s,
			),
			webkit ms
		);
	}
	&.loop {
		@include prefix(
			(
				animation-iteration-count: infinite,
			),
			webkit ms
		);
	}
}

.loader {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 5px solid rgba(#fff, 0.3);
	border-top: 5px solid map-get($theme, scColor);
	animation: spin 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

@include keyframes(navigate-bottom) {
	0% {
		opacity: 0;
		transform: rotate(45deg) translate3d(0%, 0%, 0);
	}
	99% {
		opacity: 0.5;
		transform: rotate(45deg) translate3d(100%, 100%, 0);
	}
	100% {
		opacity: 0;
		transform: rotate(45deg) translate3d(0%, 0%, 0);
	}
}

@include keyframes(navigate-top) {
	0% {
		opacity: 0;
		transform: rotate(45deg) translate3d(100%, 100%, 0);
	}
	99% {
		opacity: 0.5;
		transform: rotate(45deg) translate3d(0%, 0%, 0);
	}
	100% {
		opacity: 0;
		transform: rotate(45deg) translate3d(100%, 100%, 0);
	}
}

/* ENDS--> animations Page*/
