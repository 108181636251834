::-moz-selection {
  background-color: rgba($prColor, 0.3);
}

::selection {
  background-color: rgba($prColor, 0.3);
}

html,
body {
  width: 100%;
  height: 100%;

  // overflow: hidden;
  font-size: $baseFontSize;
}

body {
  // overflow-y: scroll;
  // /* has to be scroll, not auto
  // -webkit-overflow-scrolling: touch;
  font-weight: 400;
  line-height: 28px;
  overflow-x: hidden;
  color: map-get($theme, text);
  font-family: map-get($theme, font);
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom)
    constant(safe-area-inset-left);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  // background: map-get($theme, bg);
  // font-style: normal;
  // font-kerning: normal;
  // text-rendering: auto;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-backface-visibility: hidden;
  a {
    color: $prLink;

    &,
    & > * {
      outline: none;
    }

    & > * {
      pointer-events: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      text-decoration: none;
      color: map-get($theme, color);
    }
  }

  // b { font-family: $prFontB; font-weight: normal; }
}

svg {
  &.defs {
    width: 0;
    height: 0;
    position: absolute;
  }
}

.container-fluid {
  &.pageWrapper {
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;

    // max-width: calc(100% - 60px);
    @include box-shadow(0 0 15px rgba(0, 0, 0, 0.5));

    // @media only screen and (min-width: 1100px) {
    // 	max-width: calc(100% - 40px);
    // }
  }
}

:not(input):not(textarea) {
  @extend .nilSelect !optional;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.medium {
  font-weight: 600;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-family: map-get($theme, hFont);

  &.bold {
    font-weight: 700;
  }

  &.light {
    font-weight: 300;
  }

  &.medium {
    font-weight: 600;
  }
}

h1,
.h1 {
  line-height: 63px;
  font-size: em(49px);
}

h2,
.h2 {
  line-height: 56px;
  font-size: em(49px);
  margin-bottom: 30px;
  font-family: map-get($theme, fontL);

  .footnote-indicator {
    font-size: 21px;
  }
}

h3,
.h3 {
  font-size: em(21px);
  line-height: 31.5px;
}

h4,
.h4 {
  line-height: 42px;
  font-size: em(35px);
  margin-bottom: rem(33px);
}

h5,
.h5 {
  font-size: em(18px);
  line-height: 63px;
}

h6,
.h6 {
  font-size: em(14px);
  line-height: 63px;
}

p {
  font-weight: 500;
  margin-bottom: 0;
  line-height: 21px;
  font-size: em(14px);
  letter-spacing: -1px;
  font-family: map-get($theme, pFont);
}

blockquote {
  font-weight: 300;
  line-height: 42px;
  font-style: normal;
  font-size: em(35px);
  font-family: map-get($theme, qFont);
}

img {
  max-width: 100%;
  height: auto !important;
}

.fw,
.fh {
  position: relative;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis,
%ellipsis {
  @include ellipsis;
}

.center-block {
  display: block;
  margin: 0 auto;

  &.inline {
    text-align: center;
  }

  &.absolute {
    position: absolute;
    left: 0;
    right: 0;
  }
}

.visuallyhidden {
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}

.ui-widget {
  font-size: 1em;
}

.ui-autocomplete {
  z-index: 1000 !important;
}

@mixin pseudo {
  content: "";
  position: absolute;
}

%pseudo {
  @include pseudo;
}

.underlined {
  text-decoration: underline;
}

.animateXSlow,
%animateXSlow {
  @include prefix(
    (
      transition: all ease-in 1.3s,
    ),
    moz ms webkit
  );
}

.animateSlow,
%animateSlow {
  @include prefix(
    (
      transition: all ease-in 1s,
    ),
    moz ms webkit
  );
}

.animateNormal,
%animateNormal {
  @include prefix(
    (
      transition: all ease-in 0.6s,
    ),
    moz ms webkit
  );
}

.animateFast,
%animateFast {
  @include prefix(
    (
      transition: all ease-in 0.3s,
    ),
    moz ms webkit
  );
}

.animateXfast,
%animateXfast {
  @include prefix(
    (
      transition: all ease-in 0.1s,
    ),
    moz ms webkit
  );
}

@mixin nilSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nilSelect,
%nilSelect {
  @include nilSelect;
}

@mixin notaList {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.notaList,
%notaList {
  @include notaList;
}

%clearfix {
  @include clearfix;
}

.circle,
%circle {
  @include border-radius(50%);
}

.inlineSpaceFix,
%inlineSpaceFix {
  font-size: 0;
  white-space: nowrap;
}

@mixin ghostVcenter {
  & {
    text-align: left;

    &:before {
      width: 1px;
      width: 0.1px;
      content: " ";
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }

    .vAlign {
      display: inline-block;
      vertical-align: middle;
      max-width: calc(100% - 0.5em);
    }
  }
}

.ghostVcenter,
%ghostVcenter {
  @include ghostVcenter;
}

.middleAlign {
  top: 50%;
  position: relative;

  @include prefix(
    (
      transform: translateY(-50%),
    ),
    webkit ms
  );
}

.h-center {
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
  table-layout: fixed;
}

.v-center {
  display: table-cell;
  vertical-align: middle;
}

.scroll,
.scrollX,
.scrollY {
  overflow: scroll;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

%scrollX {
  overflow-y: hidden;
  overflow-x: auto;
}

%scrollY {
  overflow-x: hidden;
  overflow-y: auto;
}
