@import "theme";
@import "bsVars";

$baseFontSize: 14px;

$Txt: map-get($theme, text);

$prColor: map-get($theme, color);
$scColor: map-get($theme, scColor);

$prLink: map-get($theme, link);

$Brdr: #CCC;

$colorType: map-get($theme, types);
$color: map-get($theme, types);

$bgColor:
	"default"	#fff	$Txt,
	"primary"	map-get($colorType, primary)	#fff,
	"success"	map-get($colorType, success)	#fff,
	"info"	map-get($colorType, info)	#fff,
	"warning"	map-get($colorType, warning)	#fff,
	"danger"	map-get($colorType, danger)	#fff,
	"theme" $prColor	#fff,
	"secondary" $scColor	#fff
;

$img: "../images";
$fonts: "../fonts";

$icomoon-font-path: "#{$fonts}/icon";