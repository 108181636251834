footer.footer {
  right: 0;
  bottom: 0;
  z-index: 99;
  color: #fff;
  min-width: 40%;
  padding: 0 15px;
  text-align: right;
  position: absolute;
  font-family: $pFont;
  @include linear-gradient(to right, rgba(map-get($craft, color), 0.5) 0%, rgba(map-get($craft, colorL), 0) 100%);
  // Keep the Order
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  a {
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 767px) {
    bottom: 110px;
    .seperator {
      height: 1px;
      width: 100%;
      font-size: 0;
      margin: 5px 0;
      display: block;
      background: rgba(#fff, 0.5);
    }
  }
}
