/**!
 * @license
 * MyFonts Webfont Build ID 3595992, 2018-06-13T11:07:07-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: BodoniSevITC-Book by ITC
 * URL: https://www.myfonts.com/fonts/itc/bodoni-seventy-two/book/
 * Copyright: Copyright 1994 International Typeface Corporation. All rights reserved.
 * 
 * Webfont: BodoniSevITC-BookSC by ITC
 * URL: https://www.myfonts.com/fonts/itc/bodoni-seventy-two/sc-book/
 * Copyright: Copyright 1994 International Typeface Corporation. All rights reserved.
 * 
 * Webfont: BodoniSevITC-BookOS by ITC
 * URL: https://www.myfonts.com/fonts/itc/bodoni-seventy-two/os-book/
 * Copyright: Copyright 1994 International Typeface Corporation. All rights reserved.
 * 
 * Webfont: LetterGothicMTStd by Monotype 
 * URL: https://www.myfonts.com/fonts/mti/letter-gothic/std-regular/
 * Copyright: Font software Copyright 1994 Adobe Systems Incorporated. Typeface designs Copyright 1994 The Monotype Corporation. All rights reserved.
 * 
 * Webfont: LetterGothicMTStd-Bold by Monotype 
 * URL: https://www.myfonts.com/fonts/mti/letter-gothic/std-bold/
 * Copyright: Font software Copyright 1994 Adobe Systems Incorporated. Typeface designs Copyright 1994 The Monotype Corporation. All rights reserved.
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3595992
 * Licensed pageviews: 250,000
 * 
 * © 2018 MyFonts Inc
*/

@include new-font-face('robotocondensed light', '#{$fonts}/robotocondensed-light', 300);
@include new-font-face('robotocondensed regular', '#{$fonts}/robotocondensed-regular', 400);
@include new-font-face('robotocondensed bold', '#{$fonts}/robotocondensed-bold', 600);

@import url("//hello.myfonts.net/count/36ded8");
  
@font-face { font-family: 'BodoniSevITC Book';src: url('#{$fonts}/36DED8_0_0.eot');src: url('#{$fonts}/36DED8_0_0.eot?#iefix') format('embedded-opentype'),url('#{$fonts}/36DED8_0_0.woff2') format('woff2'),url('#{$fonts}/36DED8_0_0.woff') format('woff'),url('#{$fonts}/36DED8_0_0.ttf') format('truetype');}  
// @font-face { font-family: 'BodoniSevITC BookSC';src: url('#{$fonts}/36DED8_1_0.eot');src: url('#{$fonts}/36DED8_1_0.eot?#iefix') format('embedded-opentype'),url('#{$fonts}/36DED8_1_0.woff2') format('woff2'),url('#{$fonts}/36DED8_1_0.woff') format('woff'),url('#{$fonts}/36DED8_1_0.ttf') format('truetype'); }
// @font-face { font-family: 'BodoniSevITC BookOS';src: url('#{$fonts}/36DED8_2_0.eot');src: url('#{$fonts}/36DED8_2_0.eot?#iefix') format('embedded-opentype'),url('#{$fonts}/36DED8_2_0.woff2') format('woff2'),url('#{$fonts}/36DED8_2_0.woff') format('woff'),url('#{$fonts}/36DED8_2_0.ttf') format('truetype'); }
@font-face { font-family: 'LetterGothicMTStd';src: url('#{$fonts}/36DED8_3_0.eot');src: url('#{$fonts}/36DED8_3_0.eot?#iefix') format('embedded-opentype'),url('#{$fonts}/36DED8_3_0.woff2') format('woff2'),url('#{$fonts}/36DED8_3_0.woff') format('woff'),url('#{$fonts}/36DED8_3_0.ttf') format('truetype'); }
@font-face { font-family: 'LetterGothicMTStd Bold';src: url('#{$fonts}/36DED8_4_0.eot');src: url('#{$fonts}/36DED8_4_0.eot?#iefix') format('embedded-opentype'),url('#{$fonts}/36DED8_4_0.woff2') format('woff2'),url('#{$fonts}/36DED8_4_0.woff') format('woff'),url('#{$fonts}/36DED8_4_0.ttf') format('truetype'); }

@import url("//hello.myfonts.net/count/3cc1cc");

@font-face {
  font-family: 'HelveticaNowText-Bold';
  src: url('#{$fonts}/HelveticaNowText-Bold/font.woff2') format('woff2'), url('#{$fonts}/HelveticaNowText-Bold/font.woff') format('woff');
}